import { isInViewport, getPercentOfView } from './utils/dom';
import { os, device } from './utils/env';
import { CLEVERPUSH_API_ENDPOINTS, getApiEndpoint } from './utils/getApiEndpoint';

// aspect ratio is NOT 9:16 like mentioned in AMP story docs, it's 9:15 (only google knows why..)
const ASPECT_RATIO = 0.6;
const AMP_CONFIG_REGULAR = (widgetConfig = {}) => ({
  controls: [
    {
      name: 'share',
      visibility: 'hidden'
    },
    {
      name: 'close',
      position: 'end',
    },
    navigator.share && widgetConfig.stories?.[0]?.content?.pages?.[0]?.pageAttachmentUrl ? {
      name: 'shareCustom',
      backgroundImageUrl: 'data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" fill="none"><path fill="%23fff" d="m21.1 25.4 9.87-9.86 1.42 1.42-9.86 9.87z"/><path stroke="%23fff" stroke-linecap="round" stroke-width="1.9" d="m16.85 19.82 15.33-4.14c.04 0 .07.03.06.06l-4.02 15.34a1.6 1.6 0 0 1-2.97.33l-2.93-5.73-5.77-2.88a1.6 1.6 0 0 1 .3-2.98z"/></svg>',
      position: 'end',
    } : null,
  ].filter(Boolean),
  behavior: {
    on: 'end',
    action: 'circular-wrapping'
  },
});
const AMP_CONFIG_PREVIEW = {
  controls: [
    {
      name: 'share',
      visibility: 'hidden'
    },
    {
      name: 'close',
      position: 'end',
    },
    {
      name: 'skip-next',
      visibility: 'hidden'
    }
  ],
  behavior: {
    pageScroll: false,
    autoplay: false
  },
};
const MAX_PAGE_PREVIEW = 2;
const ZINDEX_RANGE = 2147483647;
const TEXT_ELEMENTS = ['heading1', 'heading2', 'heading3', 'paragraph'];
const STORY_TYPES = {
  MEDIA: 'MEDIA',
  MEDIA_TEXT: 'MEDIA_TEXT'
};

const DATALAYER_EVENT_TYPES = {
  story_show: 'story_show',
  story_click: 'story_click',
  story_start: 'story_start',
  story_finish: 'story_finish'
};

const STORY_CLICK_TYPES = {
  open: 'open',
  close: 'close'
};

const API_ENDPOINT = 'https://api.mycleverpush.com';

const GOOGLE_DATALAYER_EVENT_TYPE = 'ga4_event';

const GOOGLE_DATALAYER_PARAMS = {
  PARAMETER_NAMES: {
    ONE: {
      CLICK: 'click_type',
    },
    TWO: {
      STORY: 'story_type',
    },
    THREE: {
      COUNT: 'story_count',
    },
    FOUR: {
      LIVE_TIME: 'story_time',
    },
  },
  PARAMETER_VALUES: {
    ONE: STORY_CLICK_TYPES,
  },
};

function getDatalayerEventName(channel, storyType) {
  if (channel?.dataLayerTrackingEventNamePrefix) {
    return `${channel.dataLayerTrackingEventNamePrefix}_${storyType}`;
  }
  return storyType;
}

function getDatalayerPayload(
  eventType,
  channel,
  storyType,
  clickType,
  storyCount,
  liveTime
) {
  try {
    if (!DATALAYER_EVENT_TYPES[eventType]) {
      return null;
    }
    const payload = {
      event: GOOGLE_DATALAYER_EVENT_TYPE,
      eventname: getDatalayerEventName(channel, eventType),
    };

    switch (eventType) {
      case DATALAYER_EVENT_TYPES.story_click:
        payload.parametername1 = GOOGLE_DATALAYER_PARAMS.PARAMETER_NAMES.ONE.CLICK;
        payload.parametername2 = GOOGLE_DATALAYER_PARAMS.PARAMETER_NAMES.TWO.STORY;
        payload.parametername3 = GOOGLE_DATALAYER_PARAMS.PARAMETER_NAMES.THREE.COUNT;

        payload.parametervalue1 = STORY_CLICK_TYPES[clickType];
        payload.parametervalue2 = storyType;
        payload.parametervalue3 = storyCount;
        break;

      case DATALAYER_EVENT_TYPES.story_start:
        payload.parametername2 = GOOGLE_DATALAYER_PARAMS.PARAMETER_NAMES.TWO.STORY;
        payload.parametername3 = GOOGLE_DATALAYER_PARAMS.PARAMETER_NAMES.THREE.COUNT;

        payload.parametervalue2 = storyType;
        payload.parametervalue3 = storyCount;
        break;

      case DATALAYER_EVENT_TYPES.story_finish:
        payload.parametername2 = GOOGLE_DATALAYER_PARAMS.PARAMETER_NAMES.TWO.STORY;
        payload.parametername3 = GOOGLE_DATALAYER_PARAMS.PARAMETER_NAMES.THREE.COUNT;
        payload.parametername4 = GOOGLE_DATALAYER_PARAMS.PARAMETER_NAMES.FOUR.LIVE_TIME;

        payload.parametervalue2 = storyType;
        payload.parametervalue3 = storyCount;
        payload.parametervalue4 = liveTime;
        break;

      default:
        break;
    }

    return payload;
  } catch (error) {
    return null;
  }
}

function pushDataLayers(payload) {
  if (payload) {
    window?.dataLayer?.push(payload);
  }
}

class CleverPushStories {
  constructor() {
    window.cleverPushStoryInitDone = true;

    this.apiEndpoint = getApiEndpoint();
    if (this.apiEndpoint === CLEVERPUSH_API_ENDPOINTS.PRODUCTION) {
      // override API endpoint to mycleverpush.com domain so it will not get blocked by adblockers
      this.apiEndpoint = API_ENDPOINT;
    }
    if (window.cleverPushConfig && window.cleverPushConfig.apiEndpoint) {
      this.apiEndpoint = window.cleverPushConfig.apiEndpoint;
    }

    require('./story/story-widget.scss');

    this.consent = 'UNKNOWN';

    const storyDiv = document.querySelector('.cleverpush-story-widget[story-id][channel-id], .cleverpush-story-widget[data-story-id][data-channel-id]');
    if (storyDiv) {
      const isStoryPreviewOnly = storyDiv.dataset.storyPreview;
      if (isStoryPreviewOnly === 'true') {
        this.initStoryPreview(storyDiv);
        return;
      }
      this.initStory(storyDiv);
    } else {
      this.initTcf().then(() => {
        this.initWidgets();
      });
    }
  }

  getTotalPagesOfStories(stories) {
    if (Object.keys(stories).length === 0) {
      return 0;
    }
    return stories?.content?.pages ? stories.content.pages?.length : 0;
  }

  pausePreviewPlayer = () => {
    const previewPlayer = document.querySelector('#cleverpush-preview-player');
    if (previewPlayer) {
      previewPlayer.pause();
    }
  }

  /* eslint no-labels: ["error", { "allowLoop": true }] */
  getStoryMediaType = (story = {}) => {
    try {
      const pages = story?.content?.pages;
      let containsText = false;
      // eslint-disable-next-line no-restricted-syntax
      pagesLoop:
      for (let i = 0; i <= pages.length; i += 1) {
        const page = pages[i];
        const layers = page?.layers || [];
        for (let j = 0; j <= layers.length; j += 1) {
          const layer = layers[j];
          const elements = layer?.elements || [];

          for (let k = 0; k <= elements.length; k += 1) {
            const element = elements[k];
            if (TEXT_ELEMENTS.includes(element?.type)) {
              containsText = true;
              break pagesLoop;
            }
          }
        }
      }

      if (containsText) {
        return STORY_TYPES.MEDIA_TEXT;
      }

      return STORY_TYPES.MEDIA;
    } catch (error) {
      return STORY_TYPES.MEDIA;
    }
  }

  initStoryPreview(container) {
    if (container.className.includes('cleverpush-story-preview-container')) {
      // already initialized
      return;
    }

    container.className += ' cleverpush-story-preview-container';
    const storyId = container.dataset.storyId || (container.attributes.getNamedItem('story-id') || {}).nodeValue;
    const channelId = container.dataset.channelId || (container.attributes.getNamedItem('channel-id') || {}).nodeValue;
    const landscape = (container.attributes.getNamedItem('landscape') || {}).nodeValue === 'true';

    const config = {
      storyId,
      channelId,
    };
    this.getStoriesConfig(config, (storyConfig) => {
      this.loadAmpResources();

      this.storiesType = this.getStoryMediaType(storyConfig.stories);
      const copyStoriesConfig = { ...storyConfig, storyType: this.storiesType };

      const { player: previewPlayer, overlay } = this.createAMPStoryPlayer({
        widget: {}, landscape, isPreview: true, storyConfig: copyStoriesConfig
      });
      previewPlayer.id = 'cleverpush-preview-player';
      const previewPlayerUrl = `${this.apiEndpoint}/channel/${config.channelId}/story/${config.storyId}/html`;
      const previewLink = document.createElement('a');
      previewPlayer.setAttribute('layout', 'responsive');
      previewLink.setAttribute('href', previewPlayerUrl);
      previewPlayer.appendChild(previewLink);
      overlay.appendChild(previewPlayer);

      const pageLength = storyConfig?.pageCount;
      if (!pageLength) {
        return;
      }
      const pagesToShow = pageLength <= MAX_PAGE_PREVIEW ? pageLength - 1 : MAX_PAGE_PREVIEW;
      for (let page = 0; page <= pagesToShow; page += 1) {
        const url = `${this.apiEndpoint}/channel/${config.channelId}/story/${config.storyId}/html?page=${page}`;
        const a = document.createElement('a');
        const ampStoryContainer = document.createElement('div');
        const playerCardOverlay = document.createElement('div');
        playerCardOverlay.id = 'player-card-overlay';
        ampStoryContainer.className = `cleverpush-story-card-general cleverpush-story-card-${page}`;
        const { player } = this.createAMPStoryPlayer({
          widget: { variant: 'inline' }, landscape, isPreview: true, storyConfig
        });
        player.id = `cleverpush-story-player-general cleverpush-story-player-${page}`;
        player.setAttribute('layout', 'responsive');
        a.setAttribute('href', url);
        player.appendChild(a);
        ampStoryContainer.appendChild(player);
        ampStoryContainer.appendChild(playerCardOverlay);
        container.appendChild(ampStoryContainer);
        const stories = document.querySelectorAll(`.cleverpush-story-card-${page}`);
        const previewAmpPlayer = document.querySelector('#cleverpush-preview-player');
        if (stories.length > 0) {
          stories.forEach((ampStory) => {
            ampStory.addEventListener('click', () => {
              this.storyLiveTime = 0;
              this.totalStoryTime = new Date().getTime();
              const channel = storyConfig?.channel;
              this.trackOpened(null, storyConfig?.stories, channel, copyStoriesConfig?.storyType);

              previewAmpPlayer.play();
              previewAmpPlayer.show(null, `page-${page}`).then(() => {
                this.showOverlay(overlay);
                const isStartedPlayer = previewAmpPlayer.classList.contains('i-amphtml-story-player-loaded');
                if (isStartedPlayer && storyConfig.channel?.dataLayerTrackingEnabled) {
                  pushDataLayers(getDatalayerPayload(
                    DATALAYER_EVENT_TYPES.story_start,
                    storyConfig?.channel,
                    copyStoriesConfig?.storyType || STORY_TYPES.MEDIA,
                    null,
                    this.getTotalPagesOfStories(storyConfig?.stories) || 0
                  ));
                }
              }).catch((error) => console.log(error));
            });
          });
        }
      }
    });
  }

  getStoriesConfig(config, callback) {
    const { storyId, channelId, } = config;

    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${this.apiEndpoint}/channel/${channelId}/story/${storyId}/config`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const json = JSON.parse(xhr.responseText);
        callback(json);
      }
    };
    xhr.onerror = function () {
      console.log('error');
    };
    xhr.send();
  }

  initStory(container) {
    container.className += ' cleverpush-story-widget-inline';
    const _id = container.dataset.storyId || (container.attributes.getNamedItem('story-id') || {}).nodeValue;
    const channel = container.dataset.channelId || (container.attributes.getNamedItem('channel-id') || {}).nodeValue;
    const landscape = (container.attributes.getNamedItem('landscape') || {}).nodeValue === 'true';
    this.loadAmpResources(() => {
      const { player } = this.createAMPStoryPlayer({ widget: { variant: 'inline' }, landscape });
      this.initPlayer({ widget: { _id: 'generic' } }, [{ _id, channel }], player);
      container.appendChild(player);

      // fix height when width is < than expected
      const { width, height } = container.getBoundingClientRect();

      const maxHeight = width * (1 / ASPECT_RATIO);
      if (maxHeight < height) {
        player.style.height = `${maxHeight}px`;
      }
    });
  }

  getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
  }

  getViewedWidgetFromLocalStorage() {
    const alreadyViewWidget = localStorage.getItem('cleverpush-widget-viewed');
    if (alreadyViewWidget) {
      return JSON.parse(alreadyViewWidget);
    }
    return [];
  }

  initTcf() {
    return new Promise((resolve) => {
      // Get TCF2 Consent String
      // https://github.com/InteractiveAdvertisingBureau/GDPR-Transparency-and-Consent-Framework/blob/master/Consent%20string%20and%20vendor%20list%20formats%20v1.1%20Final.md
      if (typeof __tcfapi === 'function') {
        if (window.cleverPushConfig?.storiesIgnoreTcf) {
          resolve();
          return;
        }

        __tcfapi('addEventListener', 2, (tcData, success) => {
          if (success && tcData.eventStatus === 'tcloaded') {
            this.consent = tcData.tcString;
            resolve();
          }
        });
      } else {
        // fallback to euconsent-v2
        this.consent = this.getCookie('euconsent-v2');
        resolve();
      }
    });
  }

  isOverlayIsOpen = () => {
    const overlay = document.querySelector('.cleverpush-story-overlay');
    return overlay && overlay.classList.contains('show');
  }

  createAMPStoryPlayer({
    widget, landscape, isPreview = false, storyConfig = {}, widgetConfig = {}
  }) {
    const playerEl = document.createElement('amp-story-player');
    const height = widget.variant === 'inline' && widget.storyHeight ? widget.storyHeight : '100%';
    const width = landscape || height === '100%' ? '100%' : `${height * ASPECT_RATIO}px`;

    const playerConfig = document.createElement('script');
    playerConfig.type = 'application/json';
    // enable loop mode
    // https://github.com/ampproject/amphtml/blob/main/spec/amp-story-player.md#circular-wrapping
    if (isPreview) {
      playerConfig.innerHTML = JSON.stringify(AMP_CONFIG_PREVIEW);
    } else {
      playerConfig.innerHTML = JSON.stringify(AMP_CONFIG_REGULAR(widgetConfig));
    }
    playerEl.appendChild(playerConfig);

    if (window.innerWidth < 768 || (height === '100%' && width === '100%')) {
      playerEl.style.width = '100%';
      playerEl.style.height = '100%';
    } else {
      playerEl.style.width = width;
      playerEl.style.maxWidth = '100%';
      playerEl.style.height = `${height}px`;
    }
    playerEl.style.margin = '0 auto';

    let overlay;
    if (widget.variant !== 'inline') {
      const overlayClassName = 'cleverpush-story-overlay';
      overlay = document.createElement('div');
      overlay.className = overlayClassName;
      overlay.style.opacity = 0;
      overlay.style['z-index'] = -ZINDEX_RANGE;
      overlay.addEventListener('click', (e) => {
        if (e.target.className === 'cleverpush-story-overlay') {
          e.preventDefault();
          this.closeOverlay(overlay, isPreview);
        }
      });

      document.addEventListener('click', (event) => {
        const isClickInside = overlay.contains(event.target);
        if (isClickInside) {
          playerEl.pause();
          this.closeOverlay(overlay, isPreview);
        }
      });
      overlay.appendChild(playerEl);

      document.body.appendChild(overlay);

      playerEl.addEventListener('noNextStory', () => {
        const storyCloserTime = new Date().getTime() - this.totalStoryTime;
        const storyLiveTime = Math.round(storyCloserTime / 1000);
        if (this.isOverlayIsOpen() && storyConfig.channel?.dataLayerTrackingEnabled) {
          pushDataLayers(getDatalayerPayload(
            DATALAYER_EVENT_TYPES.story_finish,
            storyConfig?.channel,
            storyConfig?.storyType || STORY_TYPES.MEDIA,
            null,
            this.getTotalPagesOfStories(storyConfig?.stories) || 0,
            storyLiveTime || 0
          ));
        }
      });

      playerEl.addEventListener('amp-story-player-close', () => {
        if (this.isOverlayIsOpen() && storyConfig.channel?.dataLayerTrackingEnabled) {
          pushDataLayers(getDatalayerPayload(
            DATALAYER_EVENT_TYPES.story_click,
            storyConfig?.channel,
            storyConfig?.storyType || STORY_TYPES.MEDIA,
            STORY_CLICK_TYPES.close,
            this.getTotalPagesOfStories(storyConfig?.stories) || 0,
          ));
        }
        this.totalStoryTime = 0;
        this.pausePreviewPlayer();
        this.closeOverlay(overlay, isPreview);
      });
    }

    return { player: playerEl, overlay };
  }

  initWidgets() {
    const domForms = document.querySelectorAll('.cleverpush-story-widget');
    for (let i = 0; i < domForms.length; i += 1) {
      const shownWidgetIds = this.getViewedWidgetFromLocalStorage();
      const isAlreadySeen = shownWidgetIds.includes(domForms[i]?.dataset.id);
      if (isAlreadySeen) {
        continue;
      }
      this.getWidget(domForms[i].dataset.id, (config) => {
        const display = config.widget.display;
        const isMobile = os.name === 'Android' || os.name === 'iOS' || device.type === 'mobile';
        if ((display === 'mobile' && !isMobile)
          || (display === 'desktop' && isMobile)) {
          return;
        }
        config.isMobile = isMobile;
        this.initWidget(domForms[i], config);
      });
    }
  }

  getWidget(id, callback) {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `${this.apiEndpoint}/story-widget/${id}/config`, true);
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        const json = JSON.parse(xhr.responseText);
        callback(json);
      }
    };
    xhr.send();
  }

  loadAmpResources(callback) {
    if (window.ampStoryPlayerLoaded) {
      if (typeof callback === 'function') {
        callback();
      }
      return;
    }

    window.ampStoryPlayerLoaded = true;
    const script = document.createElement('script');
    const link = document.createElement('link');
    script.src = 'https://cdn.ampproject.org/amp-story-player-v0.js';
    script.async = true;
    script.onload = callback;
    link.href = 'https://cdn.ampproject.org/amp-story-player-v0.css';
    link.rel = 'stylesheet';
    link.type = 'text/css';
    document.head.append(script, link);
  }

  transformIndex(config, indexParam) {
    let index = indexParam;
    if (config.widget.adsEnabled) {
      const pageInterval = config.widget.adsPageInterval || 3;
      if (index > 0) {
        index += Math.ceil(index / pageInterval);
      }
    }

    return index;
  }

  initPlayer(config, stories, playerEl, index, storyItems) {
    const referer = (new URL(window.location.href)).origin;

    const mappedStories = stories.map((story, i) => {
      let url = `${this.apiEndpoint}/channel/${story.channel}/story/${story._id}/html`;
      if (config.widget.maxStoryPages) {
        url += url.includes('?') ? '&' : '?';
        url += `pageLimit=${config.widget.maxStoryPages}`;
      }
      if (config.widget.firstPageAttachment) {
        url += url.includes('?') ? '&' : '?';
        url += 'firstPageAttachment=true';
      }

      if (config.widget._id) {
        url += url.includes('?') ? '&' : '?';
        url += `widgetId=${config.widget._id}`;
      }

      if (referer) {
        url += url.includes('?') ? '&' : '?';
        url += `referer=${encodeURIComponent(referer)}`;
      }

      return {
        title: (story || {}).title || `Story ${i}`,
        href: url
      };
    });

    const adsIndices = [];
    const adsPageInterval = config.widget.adsPageInterval || 3;
    let addedStoryAds = 0;
    if (config.widget.adsEnabled) {
      mappedStories.forEach((story, currentIndex) => {
        if (currentIndex > 0 && currentIndex % adsPageInterval === 0) {
          adsIndices.push(currentIndex + addedStoryAds);
          mappedStories.splice(currentIndex + addedStoryAds, 0, {
            title: `Ad ${currentIndex}`,
            href: `${this.apiEndpoint}/story-widget/${config.widget._id}/ad?ref=${encodeURIComponent(location.href)}&consent=${this.consent}&isMobile=${config.isMobile}`
          });
          addedStoryAds += 1;
        }
      });
    }

    // append stories to player
    mappedStories.forEach((story) => {
      const a = document.createElement('a');
      a.setAttribute('id', story.id);
      a.setAttribute('href', story.href);
      a.setAttribute('title', story.title);
      playerEl.appendChild(a);
    });

    // eslint-disable-next-line no-undef
    const player = new AmpStoryPlayer(window, playerEl);
    setTimeout(() => {
      player.load();
      player.show(mappedStories[index]?.href, null);
    }, 0);
    window.currentAmpStoryPlayer = player;

    if (playerEl.addEventListener && storyItems) {
      const markShown = (shownIndex) => {
        const story = stories[shownIndex];
        const item = storyItems[shownIndex];
        if (storyItems && story && item) {
          try {
            localStorage.setItem(`cleverpush-story-viewed-${story._id}`, Date.now());
          } catch (e) {
            // ignored
          }
          if (item.className.indexOf('story-viewed') < 0) {
            item.className += ' story-viewed';
          }
        }
      };

      markShown(index);

      playerEl.addEventListener('navigation', (event) => {
        markShown(event.detail.index);
      });
    }

    playerEl.addEventListener('navigation', (event) => {
      // ads
      if (config.widget.adsEnabled) {
        const iframes = event.target.shadowRoot?.querySelectorAll('iframe') || [];
        if (adsIndices.includes(event.detail.index)) {
          iframes.forEach((frame) => {
            if (frame.title === 'Ad') {
              frame.contentWindow.postMessage({ playAd: true }, '*');
            }
          });
        } else {
          iframes.forEach((frame) => {
            if (frame.title === 'Ad') {
              frame.contentWindow.postMessage({ playAd: false }, '*');
            }
          });
        }
      }
    });

    let currentPageIndex = 0;
    playerEl.addEventListener('amp-story-player-shareCustom', () => {
      const url = config.stories[currentPageIndex]?.content?.pages?.[0]?.pageAttachmentUrl;
      if (!url) {
        return;
      }
      navigator.share({ url });
    });

    playerEl.addEventListener('storyNavigation', (event) => {
      const pageIndex = Number(event.detail.pageId.split('-')[1]);
      if (pageIndex >= 0) {
        currentPageIndex = pageIndex;
        if (adsPageInterval) {
          currentPageIndex += Math.round(currentPageIndex / adsPageInterval);
        }
      }
    });

    window.addEventListener('message', (event) => {
      // loop
      if (stories.length === 1) {
        if (event.data && event.data.app === '__AMPHTML__' && event.data.name === 'selectDocument' && event.data.data.next && event.data.type === 'q') {
          playerEl.show(null, 'page-0');
        }
      }

      if (event.data && event.data.app === 'cleverpush-story-widget' && event.data.name === 'navigate') {
        player.go(0, event.data.page, { animate: true });
      }
    });

    const storyDiv = document.querySelector('.cleverpush-story-widget');

    const blockMobileSwipeUpEvent = storyDiv?.dataset?.storyBlockSwipeUp;
    if (device.type === 'mobile' && storyDiv && blockMobileSwipeUpEvent === 'true') {
      const element = document.querySelector('.cleverpush-story-widget');
      if (element && !document.querySelector('.amp-story-player-overlay')) {
        const node = document.createElement('div');
        node.className = 'amp-story-player-overlay';
        node.style.cssText = `
          position: absolute;
          width: 100%;
          height: 90%;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: ${ZINDEX_RANGE};
          `;
        element.appendChild(node);
      }
    }
  }

  initWidget(storyDiv, config) {
    const { widget, channel, stories } = config;

    if (widget.customCss) {
      const node = document.createElement('style');
      node.innerHTML = widget.customCss;
      document.body.appendChild(node);
    }

    if (storyDiv) {
      const classNameId = `cleverpush-story-widget-${config.widget._id} cleverpush-story-widget-${config.widget.variant}`;
      storyDiv.className += ` ${classNameId}`;
      const variant = config.widget.variant || 'bubbles';

      if (!widget.storyHeight && variant === 'cards') {
        widget.storyHeight = 350;
      }
      if (widget.storyHeight) {
        storyDiv.style.height = `${widget.storyHeight}px`;
      }
      storyDiv.className += config.widget.position && config.widget.position.indexOf('fixed') > -1 ? ` cleverpush-story-widget-fixed-${config.widget.position.replace('fixed', '').toLowerCase()}` : '';

      if (config.widget.position && config.widget.position === 'sticky') {
        const Sticky = require('sticky-js');
        const className = 'cleverpush-story-widget-sticky';
        storyDiv.className += ` ${className}`;
        storyDiv.dataset.stickyWrap = 'true';
        new Sticky(`.${className}`);
      }

      if (config.widget.showAtScrollPercentage || config.widget.showAtScrollPixels) {
        storyDiv.className += ' cleverpush-story-widget-hidden';
        const scrollListener = () => {
          let show = false;
          if (config.widget.showAtScrollPercentage) {
            const scrollPercentage = getPercentOfView(document.body);
            show = scrollPercentage >= config.widget.showAtScrollPercentage;
          }
          if (!show && config.widget.showAtScrollPixels) {
            show = window.scrollY >= config.widget.showAtScrollPixels;
          }
          if (show) {
            window.removeEventListener('scroll', scrollListener);
            storyDiv.className += ' cleverpush-story-widget-visible';
          }
        };
        window.addEventListener('scroll', scrollListener);
      }

      const landscape = stories.some((s) => (s.content || {}).supportsLandscape);
      if (variant !== 'inline') {
        storyDiv.innerHTML = `
          <div class="cleverpush-story-${variant}">
          ${(stories || []).map((story) => {
    let viewed = false;
    try {
      if (localStorage.getItem(`cleverpush-story-viewed-${story._id}`)) {
        viewed = true;
      }
    } catch (e) {
      // ignored
    }
    const storyContentPreview = ((story.content || {}).preview || {});

    return `
              <div
                class="cleverpush-story-item-wrap"
                style="${variant === 'cards' && widget.storyHeight ? `min-width: ${Math.round(widget.storyHeight * 0.5629)}px;` : ''}"
              >
                <div
                  class="cleverpush-story-${variant}-item ${viewed ? 'story-viewed' : ''}"
                  data-id="${story._id}"
                >
                  <div
                    class="cleverpush-story-${variant}-item-inner"
                    style="background-image: url('${storyContentPreview.widgetSrc || storyContentPreview.posterPortraitSrc}');"
                  >
                  </div>
                </div>
                ${(story.content || {}).subtitle ? `
                  <div
                    class="cleverpush-story-item-subtitle"
                  >
                    ${(story.content || {}).subtitle}
                  </div>
                ` : ''}
              </div>
            `;
  }).join('')}
            ${variant === 'banner' ? `
              <div class="cleverpush-story-banner-text">
                ${variant === 'banner' && config.widget.title ? `
                <div class="cleverpush-story-banner-title">
                  ${config.widget.title}
                </div>
                ` : ''}
                ${variant === 'banner' && config.widget.subtitle ? `
                <div class="cleverpush-story-banner-subtitle">
                  ${config.widget.subtitle}
                </div>
                ` : ''}
                ${variant === 'banner' && config.widget.ctaText ? `
                <div class="cleverpush-story-banner-ctaText">
                  ${config.widget.ctaText}
                </div>
                ` : ''}
                </div>
                <svg class="cleverpush-story-banner-icon" width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M10.7678 2.00793L10.25 1.46521L5.99976 5.48108L1.76782 1.46521L1.25 2.00793L5.45667 5.99945L1.25 10L1.75024 10.5427L5.99976 6.51782L10.2322 10.5427L10.7678 10L6.54285 5.99945L10.7678 2.00793Z" fill="#6A6D71"/>
                </svg>
              ` : ''}</div>`;

        if (!isNaN(config.widget.margin)) {
          if (config.widget.position === 'fixedTop') {
            storyDiv.style.top = `${config.widget.margin}px`;
          }
          if (config.widget.position === 'fixedBottom') {
            storyDiv.style.bottom = `${config.widget.margin}px`;
          }
        }
        const storyItems = variant === 'banner' ? document.querySelectorAll(`.cleverpush-story-widget-${config.widget._id}`) : storyDiv.querySelectorAll(`.cleverpush-story-${variant}-item`);
        for (let i = 0; i < storyItems.length; i += 1) {
          ((index) => {
            const item = storyItems[index];
            item.addEventListener('click', (e) => {
              e.preventDefault();

              if (e.target.getAttribute('class') && e.target.getAttribute('class').indexOf('cleverpush-story-banner-icon') > -1) {
                try {
                  const previousShownWidget = this.getViewedWidgetFromLocalStorage('cleverpush-widget-viewed');
                  previousShownWidget.push(config.widget._id);
                  localStorage.setItem('cleverpush-widget-viewed', JSON.stringify(previousShownWidget));
                  item.remove();
                  return;
                } catch (error) {
                  console.log(error);
                }
              }

              this.trackOpened(config.widget, stories, channel, null);

              this.loadAmpResources(() => {
                const { player, overlay } = this.createAMPStoryPlayer({ widget, landscape, widgetConfig: config });
                this.initPlayer(config, stories, player, index, storyItems);
                this.showOverlay(overlay, item);
              });
            });
          })(i);
        }
      } else {
        storyDiv.className += ' cleverpush-story-widget-inline';

        this.loadAmpResources(() => {
          const { player } = this.createAMPStoryPlayer({ widget, landscape, widgetConfig: config });
          storyDiv.appendChild(player);

          // Init Story player (inline) after visible in viewport
          if (isInViewport(storyDiv)) {
            this.initPlayer(config, stories, player, 0);
          } else {
            const enterViewportListener = () => {
              if (isInViewport(storyDiv)) {
                window.removeEventListener('scroll', enterViewportListener);
                this.initPlayer(config, stories, player, 0);
              }
            };
            window.addEventListener('scroll', enterViewportListener, false);
          }

          if (window.innerWidth < 768) {
            const cover = document.createElement('div');
            cover.className = 'cleverpush-story-widget-inline-cover';
            cover.innerHTML = '<div class="cleverpush-story-widget-inline-cover-button">Story öffnen</div>';
            cover.addEventListener('click', (e) => {
              e.preventDefault();

              const overlayClassName = 'cleverpush-story-overlay';
              const overlay = document.createElement('div');
              overlay.className = overlayClassName;
              overlay.style.display = 'flex';
              overlay.addEventListener('click', (e) => {
                if (e.target.className === 'cleverpush-story-overlay') {
                  e.preventDefault();

                  overlay.style.display = 'none';
                  overlay.parentNode.removeChild(overlay);

                  storyDiv.appendChild(player);
                }
              });

              const close = document.createElement('div');
              close.className = 'cleverpush-story-overlay-close';
              close.innerHTML = '&times;';
              close.style.cursor = 'pointer';
              overlay.appendChild(close);
              close.addEventListener('click', (event) => {
                event.preventDefault();

                overlay.style.display = 'none';
                overlay.parentNode.removeChild(overlay);

                storyDiv.appendChild(player);
              });

              document.body.appendChild(overlay);

              overlay.appendChild(player);
            });
            storyDiv.appendChild(cover);
          }
        });
      }

      // Track Shown after visible in viewport
      if (isInViewport(storyDiv)) {
        this.trackShown(widget, stories, channel, null);
      } else {
        const enterViewportListener = () => {
          if (isInViewport(storyDiv)) {
            window.removeEventListener('scroll', enterViewportListener);
            this.trackShown(widget, stories, channel, null);
          }
        };
        window.addEventListener('scroll', enterViewportListener, false);
      }
    }
  }

  trackShown(widget, stories, channel, storyType) {
    const xhr = new XMLHttpRequest();
    xhr.open('POST', `${this.apiEndpoint}/story-widget/${widget._id}/track-shown`, true);
    xhr.send(JSON.stringify({
      stories: stories.map((s) => s._id),
    }));

    if (channel?.dataLayerTrackingEnabled) {
      pushDataLayers(getDatalayerPayload(
        DATALAYER_EVENT_TYPES.story_show,
        channel,
        storyType || STORY_TYPES.MEDIA,
        null,
        this.getTotalPagesOfStories(stories) || 0
      ));
    }
  }

  trackOpened(widget, stories, channel, storyType) {
    const xhr = new XMLHttpRequest();

    if (widget?._id) {
      xhr.open('POST', `${this.apiEndpoint}/story-widget/${widget._id}/track-opened`, true);
      xhr.send(JSON.stringify({
        stories: stories.map((s) => s._id),
      }));
    }

    if (channel?.dataLayerTrackingEnabled) {
      pushDataLayers(getDatalayerPayload(
        DATALAYER_EVENT_TYPES.story_click,
        channel,
        storyType || STORY_TYPES.MEDIA,
        STORY_CLICK_TYPES.open,
        this.getTotalPagesOfStories(stories) || 0
      ));
    }
  }

  showOverlay(overlay) {
    overlay.style.opacity = 1;
    overlay.classList.add('show');
    overlay.style['z-index'] = ZINDEX_RANGE;
  }

  closeOverlay(overlay, isPreview) {
    overlay.style.opacity = 0;
    overlay.style['z-index'] = -ZINDEX_RANGE;
    overlay.classList.remove('show');
    if (!isPreview) {
      overlay.remove();
    }
  }
}

new CleverPushStories();
